body {
  font-family: var(--fonts-sans) !important;
  background-color: var(--colors-background-inner);
}

code,
kbd,
samp,
pre {
  font-family: var(--fonts-mono) !important;
}

/* https://css-tricks.com/almanac/rules/m/media/prefers-reduced-motion/ */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
